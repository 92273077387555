<!--
File: EditForm.vue
Description: Component for adding/editing a single entry.
-->
<template>
  <div class="md-layout">
    <div v-for="field in fields" :key="field.name">
      <component :is="field.class ? 'div' : 'fragment'" :class="field.class">
        <md-field v-if="field.type !== 'custom'" :class="getClass(field.name)">
          <label v-if="field.label" for="theField">{{ $t(field.label) }}</label>
          <component id="theField" :is="componentMap[field.type] || 'md-input'" v-model="localValues[field.name]"
            v-bind="field.props" :data-vv-name="field.name" v-validate="validations[field.name]" />
        </md-field>

        <!-- Если поле кастомное -->
        <component v-else :is="field.component" v-bind="field.props" :value="localValues[field.name]"
          @input="(val) => updateValue(field.name, val)" />
      </component>
    </div>
  </div>
</template>

<script>
  import permissions from "@/mixins/permissionsMixin"   // needed for getClass method
  import { RegionsDropdown, RoadsDropdown, DepsDropdown } from '@/pages/Components'

  export default {
    name: 'edit-form',
    mixins: [permissions],

    data() {
      return {
        componentMap: {
          text: "md-input",
          textarea: "md-textarea",
          select: "md-select"
        }
      };
    },

    props: {
      fields: Array,
      value: Object,
      validations: Object
    },

    components: {
      RegionsDropdown,
      RoadsDropdown,
      DepsDropdown
    },

    methods: {
      updateValue(fieldName, value) {
        this.$set(this.localValues, fieldName, value);
      },

      async validateForm() {
        const isValid = await this.$validator.validateAll();
        return isValid;
      }
    },

    computed: {
      localValues: {
        get() {
          return this.value;
        },
        set(newValue) {
          this.$emit("input", newValue);
        }
      }
    },
  };
</script>
